@import '~antd/dist/antd.css';

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

label {
  font-size: 1em;
  padding-right: 12px;
}

input[type='radio'] {
  font-size: 0.65em;
  width: 1.2em;
  height: 1.2em;
  margin-right: 3px;
}

.black-button {
  width: fit-content;
  height: fit-content;
  background-color: #101b17;
  border: 0px;
  border-color: #101b17;
  border-style: solid;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
}

.black-button:hover {
  background-color: #101b17;
  border: 0px;
  border-color: #101b17;
  border-style: solid;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
}

.black-button:focus {
  background-color: #101b17;
  border: 0px;
  border-color: #101b17;
  border-style: solid;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
  font-weight: bold;
}

.black-button:disabled {
  background-color: #101b17;
  cursor: not-allowed;
}

.black-link-button {
  width: fit-content;
  text-decoration: none;
  background-color: #101b17;
  border: 0px;
  border-color: #101b17;
  border-style: solid;
  border-radius: 5px;
  padding: 7.7px 10px;
  color: white;
  font-weight: bold;
}

.black-link-button:hover {
  width: fit-content;
  text-decoration: none;
  background-color: #101b17;
  border: 0px;
  border-color: #101b17;
  border-style: solid;
  border-radius: 5px;
  padding: 7.7px 10px;
  color: white;
  font-weight: bold;
}

.black-link-button:focus {
  width: fit-content;
  text-decoration: none;
  background-color: #101b17;
  border: 0px;
  border-color: #101b17;
  border-style: solid;
  border-radius: 5px;
  padding: 7.7px 10px;
  color: white;
  font-weight: bold;
}

.ll-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5%;
  background-color: #101b17;
  color: white;
  text-decoration: none;
}

.ll-subheader {
  text-align: right;
  margin: 5px 0px 25px 0px;
}

.ll-header-title {
  color: white;
  font-size: 40px;
  text-decoration: none;
}

/* The following commented styles are causing
   strange flashing when the mouse is down.
   Leaving them here for further research. */
.ll-header-title:hover,
:focus,
:active {
  /* background-color: transparent; */
  /* color: white; */
  text-decoration: none;
}

.ll-header-nav {
  color: white;
  background-color: #101b17;
}

.ll-header-nav-toggle {
  color: white;
}

.ll-header-nav-dropdown {
  align-items: left;
  color: white;
  font-size: 22px;
  text-decoration: none;
  background: transparent;
  border: none;
  padding-left: 50px;
}

.ll-header-nav-dropdown:hover {
  color: white;
}

.ll-header-nav-dropdown:focus {
  color: white;
  text-decoration: none;
}

.ll-header-nav-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.ll-header-nav-dropdown-item:hover {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 0;
}

.ll-header-nav-dropdown-item-text {
  width: 100%;
  color: black;
  text-align: left;
  background-color: white;
  border: 0px;
}

.ll-header-nav-dropdown-item-text:hover {
  width: 100%;
  color: black;
  text-align: left;
  background-color: #e9ecef;
  border: 0px;
}

.ll-header-nav-dropdown-card {
  width: 100%;
  color: black;
  text-align: left;
  background-color: transparent;
  border: 0px;
}

.ll-header-nav-dropdown-card-header {
  width: 100%;
  padding: 6px 0px 3px 16px;
  color: black;
  background-color: transparent;
  border: 0px;
}

.ll-header-nav-dropdown-card-header:hover {
  width: 100%;
  color: black;
  background-color: #e9ecef;
  border: 0px;
}

.ll-header-nav-dropdown-card-body {
  width: 100%;
  color: black;
  background-color: transparent;
  border: 0px;
}

.ll-header-nav-dropdown-accordion-toggle {
  width: 100%;
  padding: 0px;
  color: black;
  text-align: left;
  background-color: transparent;
  border: 0px;
}

.ll-header-nav-dropdown-accordion-item {
  width: 100%;
  padding: 6px 0px 0px 36px;
  color: black;
  text-align: left;
  text-decoration: none;
  background-color: white;
  border: 0px;
  display: flex;
  flex-direction: column;
}

.ll-header-nav-dropdown-accordion-item:hover {
  width: 100%;
  color: black;
  text-decoration: none;
  background-color: transparent;
  border: 0px;
}

.ll-sign-in-out-link {
  color: #101b17;
  font-size: 16px;
  text-decoration: none;
  text-align: right;
  padding: 0px 5px 0px 0px;
}

.ll-sign-in-out-link:hover {
  color: #4001ff;
}

.ll-header-logo {
  width: 51.09px;
  height: 68.94px;
}

.warning-cell {
  background-color: #ffebee !important;
}
